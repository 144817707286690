<template>
  <div class="bg-white w-full p-3">
    <section class="h-full w-full">
      <PageHeader
        :heading="$t('xpbx.settings.queues.heading')"
        :showButton="false"
      />

      <!-- Queues table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="queues"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          v-if="!loading"
          :paginator="true"
          :rows="10"
          removableSort
          v-model:selection="selectedRecords"
          v-model:filters="filters"
          paginatorPosition="top"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} queues"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          selectionMode="single"
          :globalFilterFields="['name']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  @click="openNew"
                  class="mr-2 add-record-button"
                  :label="$t('xpbx.button.new')"
                />
              </div>
              <div class="flex gap-2 items-center ml-auto">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="name"
            style="width: 15%"
            :header="$t('xpbx.settings.queues.fields.name')"
          >
            <template #body="{ data }">
              {{ data?.name }}
              <TableActions
                :data="data"
                :id="data.id"
                :showEdit="false"
                :showIsActive="false"
                @delete="deleteSelectedRecord"
                :link="`/xpbx/settings/queue/${data?.id}`"
              />
            </template>
          </Column>
          <Column
            sortable
            field="queue_number"
            style="width: 10%"
            :header="$t('xpbx.settings.queues.fields.queue_number')"
          >
            <template #body="{ data }">
              {{ data?.queue_number }}
            </template>
          </Column>
          <Column
            sortable
            field="weight"
            style="width: 6%"
            :header="$t('xpbx.settings.queues.fields.weight')"
          >
            <template #body="{ data }">
              {{ data?.weight }}
            </template>
          </Column>
          <Column
            sortable
            field="wrapuptime"
            style="width: 7%"
            :header="$t('xpbx.settings.queues.fields.wrapuptime')"
          >
            <template #body="{ data }">
              {{ data?.wrapuptime }}
            </template>
          </Column>
          <Column
            sortable
            field="timeout"
            style="width: 10%"
            :header="$t('xpbx.settings.queues.fields.timeout')"
          >
            <template #body="{ data }">
              {{ data?.timeout }}
            </template>
          </Column>
          <Column
            sortable
            field="strategy"
            style="width: 8%"
            :header="$t('xpbx.settings.queues.fields.strategy')"
          >
            <template #body="{ data }">
              {{ getStrategyName(data?.strategy) }}
            </template>
          </Column>
          <Column
            sortable
            field="unblock"
            style="width: 5%"
            :header="$t('xpbx.settings.queues.fields.unblock')"
          >
            <template #body="{ data }">
              {{ data?.unblock }}
            </template>
          </Column>

          <Column
            sortable
            field="create_time"
            filterField="create_time"
            dataType="date"
            style="width: 10%"
            :header="$t('xpbx.settings.queues.fields.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.create_time" />
            </template>
          </Column>
          <Column
            sortable
            dataType="date"
            field="update_time"
            filterField="update_time"
            style="width: 10%"
            :header="$t('xpbx.settings.queues.fields.updated_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.update_time" />
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- DIalogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="selectedRecord?.name">
            {{
              $t("xpbx.settings.queues.notification.confirm_delete", {
                delete: `queue ${selectedRecord?.name}`,
              })
            }}</span
          >
        </div>
        <template #footer>
          <Button
            text
            :label="$t('xpbx.button.no')"
            @click="deleteRecordsDialog = false"
          />
          <Button
            text
            :label="$t('xpbx.button.yes')"
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create template -->
      <Dialog
        v-model:visible="queueDialog"
        :style="{ width: '900px' }"
        :header="$t('xpbx.settings.queues.dialogs.create_queue')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <div class="flex gap-10">
          <div class="w-1/2">
            <!--Name -->
            <FormInput
              id="name"
              :error="errors?.name?.[0]"
              v-model:modelValue="form.name"
              :label="$t('xpbx.settings.queues.labels.name')"
              :hint="$t('xpbx.settings.queues.hints.name')"
            />

            <!--Queue number -->
            <FormInput
              id="name"
              :error="errors?.queue_number?.[0]"
              v-model:modelValue="form.queue_number"
              :label="$t('xpbx.settings.queues.labels.queue_number')"
              :hint="$t('xpbx.settings.queues.hints.number')"
            />

            <!--Agent period -->
            <FormDropdown
              :options="periodOptions"
              :error="errors?.find_agent_period?.[0]"
              :placeholder="$t('xpbx.settings.queues.placeholders.period')"
              v-model:modelValue="form.find_agent_period"
              :label="$t('xpbx.settings.queues.labels.period')"
              :hint="$t('xpbx.settings.queues.hints.agent_period')"
            />

            <!--Weight -->
            <FormDropdown
              :options="wieghtOptionsV2"
              :error="errors?.weight?.[0]"
              :placeholder="$t('xpbx.settings.queues.placeholders.weight')"
              v-model:modelValue="form.weight"
              :label="$t('xpbx.settings.queues.labels.weight')"
              :hint="$t('xpbx.settings.queues.hints.weight')"
            />

            <!-- Connect again now -->
            <InputSwitch
              v-model:modelValue="form.connect_agent_now"
              :label="$t('xpbx.settings.queues.labels.connect_agent_now')"
              :hint="$t('xpbx.settings.queues.hints.connect_again')"
            />
          </div>
          <div class="w-1/2">
            <!--Strategy -->
            <FormDropdown
              optionLabel="name"
              :options="strategyOptions"
              :error="errors?.strategy?.[0]"
              v-model:modelValue="form.strategy"
              :hint="$t('xpbx.settings.queues.hints.strategy')"
              :label="$t('xpbx.settings.queues.labels.strategy')"
              :placeholder="$t('xpbx.settings.queues.placeholders.strategy')"
            />

            <!-- Wrapuptime -->
            <FormDropdown
              :options="wrapOptions"
              :error="errors?.wrapuptime?.[0]"
              :placeholder="$t('xpbx.settings.queues.placeholders.wrapuptime')"
              v-model:modelValue="form.wrapuptime"
              :label="$t('xpbx.settings.queues.labels.wrapuptime')"
              :hint="$t('xpbx.settings.queues.hints.wrapuptime')"
            />

            <!--Timeout -->
            <FormDropdown
              :options="timeoutSelect"
              :error="errors?.timeout?.[0]"
              v-model:modelValue="form.timeout"
              :placeholder="$t('xpbx.settings.queues.placeholders.timeout')"
              :label="$t('xpbx.settings.queues.labels.timeout')"
              :hint="$t('xpbx.settings.queues.hints.timeout')"
            />

            <!-- Unblock -->
            <InputSwitch
              v-model:modelValue="form.unblock"
              :label="$t('xpbx.settings.queues.labels.unblock')"
              :hint="$t('xpbx.settings.queues.hints.unblock')"
            />

            <!--Is active -->
            <InputSwitch
              v-model:modelValue="form.is_active"
              :label="$t('xpbx.settings.queues.labels.is_active')"
            />
          </div>
        </div>

        <div class="flex items-center justify-between gap-4"></div>

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @cancel="queueDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, onMounted, computed, inject } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
import { validateCreateQueue } from "@/composables/auth/createDomainValidations";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import DataTable from "primevue/datatable";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "Queues",

  components: {
    PageHeader,
    DateItem,
    DataTable,
    Column,
    Loader,
    Button,
    InputText,
    Dialog,
    FormInput,
    InputSwitch,
    TableActions,
    FormDropdown,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    // Datatable
    const dt = ref();
    const t = inject("t");
    const store = useStore();
    const isEdit = ref(false);
    const submitted = ref(false);
    const form = ref({
      name: "",
      wrapuptime: 0,
      queue_number: "",
      strategy: null,
      weight: 0,
      timeout: 30,
      is_active: false,
      find_agent_period: 0,
      unblock: false,
      connect_agent_now: false,
    });

    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const queueDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const selectText = t("xpbx.settings.dropdown.select_option");
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const countries = computed(() => store.getters.countries);
    const { queues, queue, loading, findQueues, createQueue, deleteQueue } =
      useQueues();
    const {
      wieghtOptionsV2,
      timeoutSelect,
      wrapOptions,
      periodOptions,
      strategyOptions,
      getOptionValue,
    } = useQueueOptions();

    const countryOptions = computed(() => {
      return countries.value.map((country) => {
        return {
          name: country.country_name,
          value: country.id,
        };
      });
    });

    const setDefaulRecord = (record) => {
      form.value = {
        name: "",
        wrapuptime: selectText,
        queue_number: "",
        strategy: {
          name: selectText,
          value: null,
        },
        weight: selectText,
        timeout: selectText,
        is_active: false,
        find_agent_period: selectText,
        unblock: false,
        connect_agent_now: false,
      };
    };

    // Data table functions
    const openNew = () => {
      setDefaulRecord();
      isEdit.value = false;
      queueDialog.value = true;
    };

    const closeDialog = (value) => {
      queueDialog.value = value;
    };

    const reset = () => {
      setDefaulRecord();
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const create = async () => {
      const formData = {
        ...form.value,
        weight: getOptionValue(form.value.weight),
        timeout: getOptionValue(form.value.timeout),
        strategy: getOptionValue(form.value.strategy),
        wrapuptime: getOptionValue(form.value.wrapuptime),
        find_agent_period: getOptionValue(form.value.find_agent_period),
        is_active: form.value.is_active ? 1 : 0,
        unblock: form.value.unblock ? 1 : 0,
        connect_agent_now: form.value.connect_agent_now ? 1 : 0,
      };

      const isValid = validateCreateQueue(formData);

      if (isValid) {
        await createQueue(formData);

        isEdit.value = false;
        queueDialog.value = false;
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteQueue(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const getStrategyName = (strategy) => {
      return strategyOptions.find((option) => option.value === strategy)?.name;
    };

    watch(queueDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findQueues();
    });

    return {
      dt,
      form,
      isEdit,
      errors,
      queue,
      loading,
      queues,
      queueDialog,
      wieghtOptionsV2,
      selectedRecord,
      countryOptions,
      selectedRecords,
      deleteRecordsDialog,
      filters,
      submitted,
      scrollHeight,
      timeoutSelect,
      strategyOptions,
      getStrategyName,
      wrapOptions,
      periodOptions,
      openNew,
      closeDialog,
      create,
      createQueue,
      deleteQueue,
      getOptionValue,
      setDefaulRecord,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.regex-example {
  color: #10b981;
  margin-top: 4px;
  display: inline-block;
}

.edit-icon {
  color: #10b981;
}
</style>